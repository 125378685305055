// 
// user-variables.scss
// Use this to override Bootstrap variables
//

//$goat-main-color: #1d1d1d;
//$goat-navbar-color: lighten(saturate($goat-main-color, 15%), 15%); // stylelint-disable-line function-disallowed-list
//$goat-navbar-light: lighten(saturate($goat-main-color, 5%), 10%); // stylelint-disable-line function-disallowed-list
//$goat-accent: #ffe484;
//
//$goat-gutter-x: 3rem;
//$goat-callout-variants: info, warning, danger !default;
//
//:root,
//[data-bs-theme="light"] {
//  --goat-main: #{$goat-main-color};
//  --goat-navbar: #{$goat-navbar-color};
//  --goat-accent: #{$goat-accent};
//  --goat-navbar-rgb: #{to-rgb($goat-navbar-color)};
//  --goat-accent-rgb: #{to-rgb($goat-accent)};
//  --goat-pink-rgb: #{to-rgb($pink-500)};
//  --goat-teal-rgb: #{to-rgb($teal-500)};
//  --docsearch-primary-color: var(--goat-navbar);
//  --docsearch-logo-color: var(--goat-navbar);
//
//  --goat-violet-bg: var(--goat-navbar);
//  --goat-sidebar-link-bg: rgba(var(--goat-navbar-rgb), .1);
//}
//
//@include color-mode(dark, true) {
//  --goat-navbar: #{mix($goat-navbar-color, $white, 75%)};
//  --goat-violet-bg: #{$goat-navbar-color};
//  --goat-sidebar-link-bg: rgba(#{to-rgb(mix($goat-navbar-color, $black, 75%))}, .5);
//}

.featured-article {
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@include media-breakpoint-up(sm) {
  .featured-article {
    -webkit-line-clamp: 2;
  }
}

@include media-breakpoint-up(md) {
  .featured-article {
    -webkit-line-clamp: 4;
  }
}

@include media-breakpoint-up(lg) {
  .featured-article {
    -webkit-line-clamp: 8;
  }
}

@include media-breakpoint-up(xl) {
  .featured-article {
    -webkit-line-clamp: 10;
  }
}

@include media-breakpoint-up(xxl) {
  .featured-article {
    -webkit-line-clamp: 12;
  }
}