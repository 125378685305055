//
// Interactive Map
// Based on https://github.com/Leaflet/Leaflet
// --------------------------------------------------


.interactive-map:focus {
  outline: none;
}


// Zoom controls

.leaflet-touch {
  .leaflet-control-layers,
  .leaflet-bar {
    border: 0;
    border-radius: var(--#{$prefix}border-radius-sm);
    box-shadow: $box-shadow;
  }

  .leaflet-bar a {
    transition: $nav-link-transition;
    color: $gray-800;
    font-weight: normal;
    line-height: 26px;

    &:first-child {
      border-bottom-color: $border-color;
      border-top-left-radius: var(--#{$prefix}border-radius-sm);
      border-top-right-radius: var(--#{$prefix}border-radius-sm);
    }

    &:last-child {
      border-bottom-left-radius: var(--#{$prefix}border-radius-sm);
      border-bottom-right-radius: var(--#{$prefix}border-radius-sm);
    }

    &:hover {
      background-color: $white;
      color: $nav-link-hover-color;
    }

    &.leaflet-disabled {
      background-color: $white;
      color: rgba($gray-600, .8);
    }
  }
}

.leaflet-left .leaflet-control {
  margin-left: $spacer;
}

.leaflet-top .leaflet-control {
  margin-top: $spacer;
}


// Link color

.leaflet-container a {
  color: var(--#{$prefix}primary);
  font-weight: $font-weight-medium;
}


// Popup

.leaflet-popup-content-wrapper {
  min-width: 17.5rem;
  padding: 0;
  border-radius: var(--#{$prefix}border-radius-lg);
  overflow: hidden;

  .leaflet-popup-content {
    width: 100% !important;
    margin: 0 !important;
    color: var(--#{$prefix}body-color);
  }
}

.leaflet-popup-content-wrapper,
.leaflet-popup-tip {
  box-shadow: 0 .1875rem .875rem rgba($gray-900, .32);
}

.leaflet-container {
  font-family: $font-family-sans-serif;

  a.leaflet-popup-close-button {
    top: .5rem;
    right: .5rem;
    width: 1.5rem;
    height: 1.5rem;
    padding: 0;
    border: $border-width solid $border-color;
    border-radius: 50%;
    color: rgba($gray-800, .7);
    font-size: 1rem;
    line-height: 1.25rem;
    text-align: center;

    &:hover {
      color: $gray-800;
    }
  }
}

.leaflet-popup:focus,
.leaflet-marker-icon:focus {
  outline: none;
}

.leaflet-popup-tip-container {
  backface-visibility: hidden;
}

@-moz-document url-prefix() {
  .leaflet-container a.leaflet-popup-close-button {
    line-height: 1.375rem;
  }
}
