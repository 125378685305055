//
// Social buttons
// --------------------------------------------------


@each $brand, $value in $social-buttons {
  .btn-#{$brand} {
    &:hover, &:focus, &:active {
      border-color: $value !important;
      background-color: $value !important;
      color: $white !important;
    }
  }
}

@each $brand, $value in $social-buttons {
  .brand-#{$brand} {
    &:hover, &:focus, &:active {
      color: $value !important;
    }
  }
}

.brand-insta {
  cursor: pointer;
}
.brand-insta stop {
  transition: stop-color 0.35s ease-out;
}
.brand-insta:not(:hover) stop {
  stop-color: currentcolor;
}

.btn-snapchat {
  &:hover, &:focus, &:active {
    color: $gray-800 !important;
  }
}
