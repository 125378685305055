.goat-navbar {
  padding: .75rem 0;
  background-color: $navbar-color;
  box-shadow: 0 .5rem 1rem rgba($black, .15), inset 0 -1px 0 rgba($white, .15);

  &::after {
    position: absolute;
    inset: 0;
    z-index: -1;
    display: block;
    content: "";
    background-image: linear-gradient(to bottom, rgba($navbar-color, 1), rgba(var($navbar-color), .95));
  }

  .goat-navbar-toggle {
    @include media-breakpoint-down(lg) {
      width: 4.25rem;
    }
  }

  .navbar-toggler {
    padding: 0;
    margin-right: -.5rem;
    border: 0;

    &:first-child {
      margin-left: -.5rem;
    }

    .bi {
      width: 1.5rem;
      height: 1.5rem;
    }

    &:focus {
      box-shadow: none;
    }
  }

  .navbar-brand {
    color: $white;
    transition: .2s ease-in-out transform; // stylelint-disable-line property-disallowed-list

    &:hover {
      transform: scale(1.1);
    }
  }

  .navbar-toggler,
  .nav-link {
    padding-right: $spacer * .25;
    padding-left: $spacer * .25;
    color: rgba($white, .85);

    &:hover,
    &:focus {
      color: $white;
      transform: scale(1.03);
    }

    &.active {
      font-weight: 600;
      color: $white;
    }
  }

  .navbar-nav-svg {
    display: inline-block;
    vertical-align: -.125rem;
  }

  .offcanvas-lg {
    background-color: $navbar-color;
    border-left: 0;

    @include media-breakpoint-down(lg) {
      box-shadow: $box-shadow-lg;
    }
  }

  .dropdown-toggle {
    &:focus:not(:focus-visible) {
      outline: 0;
    }
  }

  .dropdown-menu {
    --#{$prefix}dropdown-min-width: 12rem;
    --#{$prefix}dropdown-padding-x: .25rem;
    --#{$prefix}dropdown-padding-y: .25rem;
    --#{$prefix}dropdown-link-hover-bg: rgba(var(--#{$prefix}primary-rgb), .1);
    --#{$prefix}dropdown-link-active-bg: rgba(var(--#{$prefix}primary-rgb), 1);
    @include rfs(.875rem, --#{$prefix}dropdown-font-size);
    @include font-size(.875rem);
    @include border-radius(.5rem);
    box-shadow: $dropdown-box-shadow;

    li + li {
      margin-top: .125rem;
    }

    .dropdown-item {
      @include border-radius(.25rem);

      &:active {
        .bi {
          color: inherit !important; // stylelint-disable-line declaration-no-important
        }
      }
    }

    .active {
      font-weight: 600;

      .bi {
        display: block !important; // stylelint-disable-line declaration-no-important
      }
    }
  }
}

@include color-mode(dark) {
  .goat-navbar {
    box-shadow: 0 .5rem 1rem rgba($black, .15), inset 0 -1px 0 rgba($white, .15);
  }
}

.bi {
  width: 1em;
  height: 1em;
  vertical-align: -.125em;
  fill: currentcolor;
}
