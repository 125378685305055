// 
// user.scss
// Use this to write your custom SCSS
//


.hero-card-bg {
  background-attachment: fixed;
  background-color: #efefef;
  background-image: radial-gradient(at 30% 89%, #ffffff 0px, transparent 50%),
  radial-gradient(at 35% 0%, #e5e5e5 0px, transparent 50%),
  radial-gradient(at 93% 46%, #CCCCCC 0px, transparent 50%),
  radial-gradient(at 23% 49%, #f8f8ff 0px, transparent 50%),
  radial-gradient(at 17% 27%, #E5E6E4 0px, transparent 50%),
  radial-gradient(at 79% 30%, #F2F3F2 0px, transparent 50%),
  radial-gradient(at 26% 40%, #F9F9F8 0px, transparent 50%);
}

@include color-mode(dark) {
  .hero-card-bg {
    background-attachment: fixed;
    background-color: #29323f;
    background-image: radial-gradient(at 30% 89%, #1d1d1d 0px, transparent 50%),
    radial-gradient(at 35% 0%, #21201E 0px, transparent 50%),
    radial-gradient(at 93% 46%, #29323f 0px, transparent 50%),
    radial-gradient(at 23% 49%, #63666A 0px, transparent 50%),
    radial-gradient(at 17% 27%, #6F7378 0px, transparent 50%),
    radial-gradient(at 79% 30%, #1F2022 0px, transparent 50%),
    radial-gradient(at 26% 40%, #2E3033 0px, transparent 50%);
  }
}
